<template>
  <div>
    <h1>
      Bentornato, <br>ecco i tuoi CEP
      <div class="filters-wrapper">
        <CInputSearch id="search_term" name="search_term" placeholder="..." :readonly="false" v-model.number="search_term"></CInputSearch>
        <CSelect id="status" name="status" placeholder="Stato" :options="cep_statuses" :disabled="false" v-model.number="filters.status" @change="getInitValues('filters')"></CSelect>
        <CSelect id="ea_id" name="ea_id" placeholder="EA" :options="eas" :disabled="false" v-model.number="filters.ea_id" @change="getInitValues('filters')"></CSelect>
        <CSelect id="pl_id" name="pl_id" placeholder="PL" :options="pls" :disabled="false" v-model.number="filters.pl_id" @change="getInitValues('filters')"></CSelect>
        <CSelect id="pc_id" name="pc_id" placeholder="PC" :options="pcs" :disabled="false" v-model.number="filters.pc_id" @change="getInitValues('filters')"></CSelect>
      </div>
    </h1>
    <template v-if="ceps.length>0">
      <table class="table-wrapper">
        <thead>
          <tr>
            <th :class="order.key=='status' ? order.dir : ''">
              <span @click="toggleOrder('status')">Stato <span class="order"></span></span>
            </th>
            <th :class="order.key=='project_code' ? order.dir : ''">
              <span @click="toggleOrder('project_code')">Cod. progetto <span class="order"></span></span>
            </th>
            <th :class="order.key=='client_name' ? order.dir : ''">
              <span @click="toggleOrder('client_name')">Cliente <span class="order"></span></span>
            </th>
            <th :class="order.key=='ea_id' ? order.dir : ''">
              <span @click="toggleOrder('ea_id')">EA <span class="order"></span></span>
            </th>
            <th :class="order.key=='pl_id' ? order.dir : ''">
              <span @click="toggleOrder('pl_id')">PL <span class="order"></span></span>
            </th>
            <th :class="order.key=='pc_id' ? order.dir : ''">
              <span @click="toggleOrder('pc_id')">PC <span class="order"></span></span>
            </th>
            <th :class="order.key=='created_at' ? order.dir : ''">
              <span @click="toggleOrder('created_at')">Data creazione <span class="order"></span></span>
            </th>
            <th :class="order.key=='updated_at' ? order.dir : ''">
              <span @click="toggleOrder('updated_at')">Ultima modifica <span class="order"></span></span>
            </th>
            <th>
              <span>% Durata</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <a :href="'/ceps/'+cep.id" class="table-row table-content" v-for="cep in ceps">
            <td class="table-cell status"><span class="status" :class="cep.status">{{$getEnumLabel(cep_statuses, cep.status)}}</span></td>
            <td class="table-cell p-code">{{$niceField(cep.project_code)}}</td>
            <td class="table-cell">{{$niceField(cep.client_name)}}</td>
            <td class="table-cell">{{$niceField(cep.ea_name)}}</td>
            <td class="table-cell">{{$niceField(cep.pl_name)}}</td>
            <td class="table-cell">{{$niceField(cep.pc_name)}}</td>
            <td class="table-cell date">{{$niceDate(cep.created_at)}}</td>
            <td class="table-cell date">{{$niceDate(cep.updated_at)}}</td>
            <td class="table-cell">
              <template v-if="cep.status=='perso'">
                N/A
              </template>
              <template v-else-if="cep.starts_at && cep.finishes_at">
                <div class="progress">
                  <div class="pipe-bar" :class="dateLessThanToday(cep.finishes_at) ? 'over' : ''" :style="'left:'+getDurationPercentage(cep)+'%'"></div>
                </div>
              </template>
              <template v-else>
                -
              </template>
            </td>
          </a>
        </tbody>
      </table>
      <div class="pagination" v-if="page_number>1">
        <span class="prev" @click="prevPage"></span>
        <span v-for="page in page_range" class="item" :class="page==current_page ? 'active' : ''" @click="selectPage(page)">{{page}}</span>
        <span class="next" @click="nextPage"></span>
        <span class="total-page">di {{page_number}}</span>
      </div>
    </template>
    <template v-else>
      <p class="no-rows" v-if="ceps.length==0">La ricerca non ha prodotto alcun risultato.</p>
    </template>
  </div>
</template>

<script>
  import CSelect from "../form/CSelect.vue"
  import CInputSearch from "../form/CInputSearch.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CSelect,
      CInputSearch,
    },

    props: {
      cep_statuses: Array,
      users: Array,
    },

    data(){
      return {
        search_term: '',
        awaiting_search: false,

        filters: {
          status: null,
          ea_id: null,
          pl_id: null,
          pc_id: null,
        },

        order: {
          key: null,
          dir: null,
        },

        ceps: [],

        limit: 50,
        offset: 0,
        current_page: 1,
        page_number: 0,
        page_range: [],
        page_shown: 7,
      }
    },

    created(){
      this.getInitValues();
    },

    watch: {
      search_term: function(val){
        if(!this.awaiting_search){
          setTimeout(() => {
            this.getInitValues('filters');
            this.awaiting_search = false;
          }, 750);
        }
        this.awaiting_search = true;
      },
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pcs: function(){
        return this.users.filter((item) => { return item.is_pc; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
    },

    methods: {
      ...helperFunctions,

      dateLessThanToday(date){
        var date_obj = new Date(date);
        var today = new Date();
        return date_obj<today;
      },

      getDurationPercentage(cep){
        // Calculate difference in days between starts and finish of project
        var starts_at_date = new Date(cep.starts_at);
        var finishes_at_date = new Date(cep.finishes_at);
        var difference_in_ms  = finishes_at_date - starts_at_date;
        var diff_in_days = difference_in_ms / (1000 * 60 * 60 * 24);
        // Calculate difference in days between starts and today
        var today_diff_in_days = 0;
        var today = new Date();
        if(today>=finishes_at_date){
          today_diff_in_days = diff_in_days
        }
        else{
          if(today>starts_at_date){
            var today_difference_in_ms  = today - starts_at_date;
            var today_diff_in_days = today_difference_in_ms / (1000 * 60 * 60 * 24);
          }
        }
        return diff_in_days>0 ? today_diff_in_days/diff_in_days*100 : 0;
      },

      toggleOrder(key){
        if(this.order.key!=key){
          this.order.key = key;
          this.order.dir = 'asc';
        }
        else{
          if(this.order.dir=='asc'){
            this.order.dir = 'desc';
          }
          else{
            this.order.key = null;
            this.order.dir = null;
          }
        }
        this.getInitValues();
      },

      prevPage(){
        if(this.current_page>1){
          this.current_page--;
          this.offset--;
          this.getInitValues();
        }
      },

      selectPage(page_index){
        if(this.current_page!=page_index){
          this.current_page=page_index;
          this.offset=page_index-1;
          this.getInitValues();
        }
      },

      nextPage(){
        if(this.current_page<this.page_number){
          this.current_page++;
          this.offset++;
          this.getInitValues();
        }
      },

      calculatePageRange(){
        var temp_page_range = [];
        var start = 0;
        var end = this.page_shown;
        if(this.page_number>this.page_shown){
          if(this.current_page<4){
            start = 0;
            end = this.page_shown;
          }
          else if(this.current_page>(this.page_number-4)){
            start = this.page_number-this.page_shown;
            end = this.page_number;
          }
          else{
            start = this.current_page-Math.ceil(this.page_shown/2);
            end = this.current_page+Math.floor(this.page_shown/2);
          }
        }
        else{
          end = this.page_number;
        }
        for(var i=start;i<end;i++){
          temp_page_range.push(parseInt(i+1));
        }
        this.page_range = temp_page_range;
      },

      calculateOffset(){
        return this.limit*this.offset;
      },

      getInitValues(type=null){
        if(type=='filters'){
          this.offset = 0;
        }
        var params = {params: {limit: this.limit, offset: this.calculateOffset(), filters: JSON.parse(JSON.stringify(this.filters)), search_term: this.search_term, order: this.order}};
        this.axios.get('/api/ceps', params).then(response => {
          var obj = response.data;
          if(obj.data.success){
            this.ceps = obj.data.ceps;

            if(type=='filters'){
              this.current_page = 1;
            }

            this.page_number = Math.ceil(obj.data.ceps_count/this.limit);
            this.calculatePageRange();
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>