<template>
  <div>
    <h1>
      AppDefVals
      <div class="filters-wrapper">
        <CInputSearch id="search_term" name="search_term" placeholder="..." :readonly="false" v-model.number="search_term"></CInputSearch>
      </div>
    </h1>
    <template v-if="app_def_vals.length>0">
      <table class="table-wrapper">
        <thead>
          <tr>
            <th :class="order.key=='name' ? order.dir : ''">
              <span @click="toggleOrder('name')">Nome <span class="order"></span></span>
            </th>
            <th :class="order.key=='kind' ? order.dir : ''">
              <span @click="toggleOrder('kind')">Tipologia <span class="order"></span></span>
            </th>
            <th :class="order.key=='client_name' ? order.dir : ''">
              <span @click="toggleOrder('client_name')">Valore <span class="order"></span></span>
            </th>
            <th :class="order.key=='created_at' ? order.dir : ''">
              <span @click="toggleOrder('created_at')">Data creazione <span class="order"></span></span>
            </th>
            <th :class="order.key=='updated_at' ? order.dir : ''">
              <span @click="toggleOrder('updated_at')">Ultima modifica <span class="order"></span></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <a :href="'/admin/app_def_vals/'+app_def_val.id+'/edit'" class="table-row table-content" v-for="app_def_val in app_def_vals">
            <td class="table-cell">{{$niceField(app_def_val.name)}}</td>
            <td class="table-cell">{{niceKind(app_def_val.kind)}}</td>
            <td class="table-cell">{{niceValue(app_def_val)}}</td>
            <td class="table-cell date">{{$niceDate(app_def_val.created_at)}}</td>
            <td class="table-cell date">{{$niceDate(app_def_val.updated_at)}}</td>
          </a>
        </tbody>
      </table>
      <div class="pagination" v-if="page_number>1">
        <span class="prev" @click="prevPage"></span>
        <span v-for="page in page_range" class="item" :class="page==current_page ? 'active' : ''" @click="selectPage(page)">{{page}}</span>
        <span class="next" @click="nextPage"></span>
        <span class="total-page">di {{page_number}}</span>
      </div>
    </template>
    <template v-else>
      <p class="no-rows" v-if="app_def_vals.length==0">La ricerca non ha prodotto alcun risultato.</p>
    </template>
  </div>
</template>

<script>
  import CInputSearch from "../form/CInputSearch.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CInputSearch,
    },

    data(){
      return {
        search_term: '',
        awaiting_search: false,

        order: {
          key: null,
          dir: null,
        },

        app_def_vals: [],

        limit: 50,
        offset: 0,
        current_page: 1,
        page_number: 0,
        page_range: [],
        page_shown: 7,
      }
    },

    created(){
      this.getInitValues();
    },

    watch: {
      search_term: function(val){
        if(!this.awaiting_search){
          setTimeout(() => {
            this.getInitValues('filters');
            this.awaiting_search = false;
          }, 750);
        }
        this.awaiting_search = true;
      },
    },

    methods: {
      ...helperFunctions,

      toggleOrder(key){
        if(this.order.key!=key){
          this.order.key = key;
          this.order.dir = 'asc';
        }
        else{
          if(this.order.dir=='asc'){
            this.order.dir = 'desc';
          }
          else{
            this.order.key = null;
            this.order.dir = null;
          }
        }
        this.getInitValues();
      },

      niceKind(kind){
        var string = '-';
        switch(kind){
          case 'num':
            string = 'Numerico';
            break;
          case 'flo':
            string = 'Decimale';
            break;
          case 'str':
            string = 'Testo';
            break;
          case 'arr':
            string = 'Array';
            break;
          default:break;
        }
        return string;
      },

      niceValue(obj){
        var val = '-';
        switch(obj.kind){
          case 'num':
            val = obj.val_num;
            break;
          case 'flo':
            val = obj.val_flo;
            break;
          case 'str':
            val = obj.val_str;
            break;
          case 'arr':
            val = obj.val_arr;
            break;
          default:break;
        }
        return val;
      },

      prevPage(){
        if(this.current_page>1){
          this.current_page--;
          this.offset--;
          this.getInitValues();
        }
      },

      selectPage(page_index){
        if(this.current_page!=page_index){
          this.current_page=page_index;
          this.offset=page_index-1;
          this.getInitValues();
        }
      },

      nextPage(){
        if(this.current_page<this.page_number){
          this.current_page++;
          this.offset++;
          this.getInitValues();
        }
      },

      calculatePageRange(){
        var temp_page_range = [];
        var start = 0;
        var end = this.page_shown;
        if(this.page_number>this.page_shown){
          if(this.current_page<4){
            start = 0;
            end = this.page_shown;
          }
          else if(this.current_page>(this.page_number-4)){
            start = this.page_number-this.page_shown;
            end = this.page_number;
          }
          else{
            start = this.current_page-Math.ceil(this.page_shown/2);
            end = this.current_page+Math.floor(this.page_shown/2);
          }
        }
        else{
          end = this.page_number;
        }
        for(var i=start;i<end;i++){
          temp_page_range.push(parseInt(i+1));
        }
        this.page_range = temp_page_range;
      },

      calculateOffset(){
        return this.limit*this.offset;
      },

      getInitValues(type=null){
        if(type=='filters'){
          this.offset = 0;
        }
        var params = {params: {limit: this.limit, offset: this.calculateOffset(), search_term: this.search_term, order: this.order}};
        this.axios.get('/api/app_def_vals', params).then(response => {
          var obj = response.data;
          if(obj.data.success){
            this.app_def_vals = obj.data.app_def_vals;

            if(type=='filters'){
              this.current_page = 1;
            }

            this.page_number = Math.ceil(obj.data.app_def_vals_count/this.limit);
            this.calculatePageRange();
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>